'use client'
import { giphyUrl } from '@/app/util/env'
import { TakeoverData } from '@/app/util/searchbar-takeover'
import { desktopSearchbarPadding, searchbarHeight } from './constants'

const Takeover = ({ takeover }: { takeover: TakeoverData }) => {
    const { host, pathname } = new URL(takeover.img_src)
    const src = host === 'giphy.com' ? `${giphyUrl}${pathname}` : takeover.img_src
    return (
        <a
            href={takeover.href}
            target="_blank"
            style={{ top: desktopSearchbarPadding, right: searchbarHeight }}
            className="absolute hidden cursor-pointer md:block"
        >
            <img src={src} height={searchbarHeight} width={400} alt="" />
        </a>
    )
}
export default Takeover
