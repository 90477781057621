'use client'

import AdsContext from '@/context/ads'
import UAParserContext from '@/context/ua-parser'
import { giphyDarkGrey, giphyDarkestGrey } from '@giphy/colors'
import { useContext } from 'react'
import styled, { css } from 'styled-components'
import { aboveHeaderZIndex } from 'ui/src/constants'
import { desktop } from 'ui/src/css'

export const DESKTOP_CONTAINER_HEIGHT = 120
export const MOBILE_CONTAINER_HEIGHT = 70
export const DESKTOP_AD_DIMENSIONS = [728, 90]
export const MOBILE_AD_DIMENSIONS = [320, 50]
export const UNIT_NAME = 'top_leaderboard'

const AdContainer = styled.div`
    position: sticky;
    top: 0;
    z-index: ${aboveHeaderZIndex + 1};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: ${giphyDarkestGrey};
    height: ${MOBILE_CONTAINER_HEIGHT}px;
    ${desktop(css`
        height: ${DESKTOP_CONTAINER_HEIGHT}px;
    `)}
`

const AdPlaceholder = styled.div`
    background-color: ${giphyDarkGrey};
    width: ${MOBILE_AD_DIMENSIONS[0]}px;
    height: ${MOBILE_AD_DIMENSIONS[1]}px;
    border-radius: 4px;
    overflow: hidden;
    ${desktop(css`
        width: ${DESKTOP_AD_DIMENSIONS[0]}px;
        height: ${DESKTOP_AD_DIMENSIONS[1]}px;
    `)}
`

const TopLeaderboardAd = () => {
    const { deviceType } = useContext(UAParserContext)
    const { isAdUnitEnabled, pageName } = useContext(AdsContext)
    const className = `htlad-${deviceType}_${UNIT_NAME}_${pageName}`
    const isEnabled = isAdUnitEnabled(UNIT_NAME)

    return (
        isEnabled && (
            <AdContainer>
                <AdPlaceholder>
                    <div className={className}></div>
                </AdPlaceholder>
            </AdContainer>
        )
    )
}

export default TopLeaderboardAd
