'use client'
import { addFavorite, checkIsFavorite, removeFavorite } from '@/api/favorites'
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useRef, useState } from 'react'
import { ILoggedInUser } from 'types'
import UserContext from './user-context'

export type FavoritesContextProps = {
    favorites: string[]
    setFavorites: Dispatch<SetStateAction<string[]>>
    hasChecked: string[]
    setHasChecked: Dispatch<SetStateAction<string[]>>
}

const FavoritesContext = createContext({
    favorites: [] as string[],
    setFavorites: () => {},
    hasChecked: [] as string[],
    setHasChecked: () => {},
} as FavoritesContextProps)

const useHydratedLocalStorage = (key: string) => {
    const { user } = useContext(UserContext)
    const state = useState<string[]>([])
    const [hyrdated, setHydrated] = useState(false)
    const startedSync = useRef(false)
    const [storedValue, setStoredValue] = state
    useEffect(() => {
        // put in local storage if no user
        if (!user) {
            if (!hyrdated) {
                try {
                    const item = window.localStorage.getItem(key)
                    setStoredValue(item ? JSON.parse(item) : [])
                    setHydrated(true)
                } catch (error) {}
            } else {
                try {
                    window.localStorage.setItem(key, JSON.stringify(Array.from(new Set(storedValue))))
                } catch (error) {
                    console.error(`failed to set ${key} in local`)
                }
            }
        } else if (!startedSync.current) {
            startedSync.current = true
            const item = window.localStorage.getItem(key)
            const toSync: string[] = item ? JSON.parse(item) : []
            syncFavorite(toSync, user, key)
        }
    }, [hyrdated, key, setStoredValue, storedValue, user])
    return state
}

const localFavoritesKey = 'local-favorites'

export const FavoritesManager = ({ children }: { children: ReactNode }) => {
    const [favorites, setFavorites] = useHydratedLocalStorage(localFavoritesKey)
    const [hasChecked, setHasChecked] = useState<string[]>([])
    useEffect(() => {}, [])
    return (
        <FavoritesContext.Provider value={{ favorites, setFavorites, hasChecked, setHasChecked }}>
            {children}
        </FavoritesContext.Provider>
    )
}

async function syncFavorite(ids: string[], user: ILoggedInUser, key: string) {
    const id = ids[0]
    if (id) {
        try {
            setTimeout(async () => {
                await addFavorite(id, user.token, document.cookie)
                window.localStorage.setItem(key, JSON.stringify(ids.filter((f) => f !== id)))
                syncFavorite(ids.slice(1), user, key)
            }, 100)
        } catch (error) {
            console.error(`failed to sync favorite ${id}`, error)
        }
    }
}

/**
 * Will check the endpoint once to see if the gif is favorited
 * @param id gif id to check
 * @returns isFavorite boolean
 */
export function useCheckFavorite(id?: string) {
    const { user } = useContext(UserContext)
    const { favorites, setFavorites, hasChecked, setHasChecked } = useContext(FavoritesContext)
    const isChecking = useRef(false)
    useEffect(() => {
        async function checkFavorite(id: string) {
            if (user) {
                const isFavorite = await checkIsFavorite(id as string, user.token)
                // update state
                setFavorites((favs) => {
                    const hasIt = favs.includes(id)
                    if (isFavorite && !hasIt) {
                        return [...favs, id]
                    } else if (!isFavorite && hasIt) {
                        return favs.filter((f) => f !== id)
                    }
                    return favs
                })
                // flag for checked so we don't fetch again
                setHasChecked((checked) => [...checked, id])
            } else {
                console.error('no user in useFavorites hook')
            }
        }
        if (id && !isChecking.current && !hasChecked.includes(id)) {
            isChecking.current = true
            checkFavorite(id)
        }
    }, [hasChecked, id, setFavorites, setHasChecked, user])
    if (id) {
        if (!user) {
            // local favorites
            return favorites.includes(id)
        }
        return hasChecked.includes(id) ? favorites.includes(id) : undefined
    }
    return undefined
}

export function useToggleFavorites() {
    const { user } = useContext(UserContext)
    const { favorites, setFavorites } = useContext(FavoritesContext)
    async function toggleFavorite(id: string) {
        const isFavorite = favorites.includes(id)
        if (user) {
            if (!isFavorite) {
                await addFavorite(id, user.token, document.cookie)
                setFavorites((favs) => {
                    return [...favs, id]
                })
            } else if (isFavorite) {
                await removeFavorite(id, user.token, document.cookie)
                setFavorites((favs) => {
                    return favs.filter((f) => f !== id)
                })
            }
            return !isFavorite
        } else {
            if (!isFavorite) {
                setFavorites((favs) => {
                    return [...favs, id]
                })
            } else {
                setFavorites((favs) => {
                    return favs.filter((f) => f !== id)
                })
            }
        }
    }
    return toggleFavorite
}
export default FavoritesContext
