'use client'

import { publicRuntimeConfig } from '@/app/util/env'
import { createContext, ReactNode, useContext } from 'react'
import HeaderSearchBar from '../../components/search-bar'
import UAParserContext from '../../context/ua-parser'
import type { TakeoverData } from '../util/searchbar-takeover'

export const AdProductContext = createContext({
    takeover: undefined as TakeoverData | undefined,
})

type Props = { children: ReactNode; initialTerm?: string; countryCode?: string; takeover?: TakeoverData }
const HeaderInit = ({ children, initialTerm, takeover }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    return (
        <AdProductContext.Provider value={{ takeover }}>
            <HeaderSearchBar
                initialTerm={initialTerm}
                apiKey={deviceType === 'mobile' ? publicRuntimeConfig.mobileApiKey : publicRuntimeConfig.apiKey}
            >
                {children}
            </HeaderSearchBar>
        </AdProductContext.Provider>
    )
}
export default HeaderInit
